<template>
    <div id="login">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="login-content d-flex row">
                        <div class="col-md-6 pr-0 order-1 order-md-0">
                            <div v-bind:style="{ backgroundImage: 'url(' + require('@/assets/images/banner-login.png') + ')' }" class="login-banner d-flex">
                                <div class="align-self-center">
                                    Membangun kebersamaan  dalam menghasilkan Co-Creation dan Collaboration
                                </div>
                            </div>
                        </div>
                        <div class="login-form col-md-6 order-0 order-md-1">
                            <h3>Lupa Kata Sandi?</h3>
                            <p>Silakan masukkan email yang telah terdaftar</p>
                            <br><br>
                            <form @submit.prevent="actionForgot()">
                                <input type="text" v-model="email" name="email" class="form-control" placeholder="Masukkan Email Anda">
                                <button class="btn btn-primary fullsize-btn" :class="disable_submit">
                                    <span v-if="disable_submit == ''">Forgot</span>
                                    <b-spinner v-if="disable_submit == 'disabled'" label="Loading..." small />
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import axios from 'axios'

    export default {
        name: 'Forgot',
        data () {
            return {
                email: '',
                disable_submit: ''
            }
        },
        methods: {
            async actionForgot () {
                this.disable_submit = 'disabled'
                const response = await axios.post('password/email', {
                    email: this.email
                }).catch(e => e.error)

                if (response) {
                    if (response.data) {
                        this.disable_submit = ''
                        this.$swal(
                            'Success!',
                            'We have sent a link to change your password in your email.',
                            'success'
                        )
                    } else {
                        this.disable_submit = ''
                        this.$swal({
                            icon: 'error',
                            title: 'Oops...',
                            text: 'Ops an error occurred'
                        })
                    }
                } else {
                    this.disable_submit = ''
                    this.$swal({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Ops an error occurred'
                    })
                }
            }
        }
    }
</script>
